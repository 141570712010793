import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout.js'
import PostList from '../components/parts/postList.js'
import SEO from '../components/parts/seo.js'
import Pagination from '../components/parts/pagination.js'

const Category = ({ data, pageContext, uri }) => {
  const categories = data.site.siteMetadata.categories;
  const CurrentCategories = categories.find(category => category.slug === pageContext.category);

  let pageData = {
    title: CurrentCategories.name || '',
    description: CurrentCategories.description || ''
  }

  const pageTitle = {
    mainTitle: {
      lang: '',
      text: pageData.title
    },
    subTitle: {
      lang: 'en',
      text: '<Category />'
    }
  }

  return (
    <React.Fragment>
      <SEO
        title={pageData.title}
        description={pageData.description}
        pathname={uri + '/'}
        article
      />
      <Layout sidebar pickup pageTitle={pageTitle}>
        <PostList posts={data.allMdx.edges} col="2" />
        <Pagination archiveType="category" category={CurrentCategories} currentPage={pageContext.currentPage} numPages={pageContext.categoryNumPages} />
      </Layout>
    </React.Fragment>
  )
}
export default Category

export const pageQuery = graphql`
  query BlogPostByCategory($category: String) {
    site {
      siteMetadata {
        categories {
          name
          slug
          description
        }
      }
    }
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: $category } } }
    ) {
        edges {
          node {
            id
            frontmatter {
              date
              path
              title
              categories
              featuredImage {
              childImageSharp {
                fluid(maxWidth: 678) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
