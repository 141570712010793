import React from 'react'
import { Link } from 'gatsby'
import Icon from './icon.js'
import './pagination.scss'

const Pagination = ({ archiveType, category, currentPage, numPages }) => {
  let pathPrefix = '';

  if (archiveType === 'category') {
    pathPrefix = '/category/' + category.slug
  }

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? pathPrefix + '/' : (currentPage - 1).toString()
  const nextPage = pathPrefix + '/page/' + (currentPage + 1).toString() + '/'

  return (
    <ul className="pagination">
      {!isFirst && (
        <li className="pagination__item">
          <Link to={prevPage} rel="prev" className="pagination__text pagination__text_link">
            <Icon type="angle_left" />
            <span className="screen-reader-text">前のページへ</span>
          </Link>
        </li>
      )}
      {Array.from({ length: numPages }, (_, i) => (
        <li key={`pagination-number${i + 1}`} className="pagination__item">
          {
            i + 1 === currentPage ? <span className="pagination__text pagination__text_is-now">{i + 1}</span> : <Link to={i === 0 ? pathPrefix + '/' : `${pathPrefix}/page/${i + 1}/`} className="pagination__text pagination__text_link">{i + 1}</Link>
          }
        </li>
      ))}
      {!isLast && (
        <li className="pagination__item">
          <Link to={nextPage} rel="next" className="pagination__text pagination__text_link">
            <Icon type="angle_right" />
            <span className="screen-reader-text">次のページへ</span>
          </Link>
        </li>
      )}
    </ul>
  )
}

export default Pagination;
